var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: "组织架构",
              isShowdig: false,
              showCheckbox: false,
              showTopTip: false,
              expandOnClickNode: false,
              treeExpand: false,
              nodeKey: "id",
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              defaultCheckedKeys: _vm.defaultCheckedKeys,
              loading: _vm.treeLoading,
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            {
              staticStyle: {
                position: "relative",
                height: "calc(100vh - 94px)",
              },
            },
            [
              _c(
                "head-layout",
                {
                  staticClass: "head-section",
                  attrs: {
                    "head-btn-options": _vm.headBtnOptions,
                    "head-title": "境内项目人员信息",
                  },
                  on: {
                    "head-save": _vm.headSave,
                    "head-update": _vm.headUpdate,
                    "head-submit": _vm.headSubmit,
                    "head-summary": _vm.headSummary,
                    "head-reportView": _vm.headReportView,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "left" },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          type: "month",
                          format: "yyyy-MM",
                          "value-format": "yyyy-MM",
                          size: "mini",
                          disabled: _vm.type === "workflow",
                        },
                        on: { change: _vm.selectList },
                        model: {
                          value: _vm.query.formDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.query, "formDate", $$v)
                          },
                          expression: "query.formDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.dataForm,
                        rules: _vm.rules,
                        "label-width": "240px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "组织名称", prop: "orgName" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.dataForm.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "orgName", $$v)
                                      },
                                      expression: "dataForm.orgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "填报日期",
                                    prop: "updateTime",
                                  },
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("span", [_vm._v("填报日期:")]),
                                  ]),
                                  _c("el-date-picker", {
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      disabled: "true",
                                      placeholder: "保存后自动生成",
                                    },
                                    model: {
                                      value: _vm.dataForm.updateTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "updateTime",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.updateTime",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "数据状态",
                                    prop: "dataState",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.dataForm.dataState,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "dataState",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.dataState",
                                      },
                                    },
                                    _vm._l(_vm.dataStateDic, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "custom-row", attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "公司在册职工",
                                    prop: "regeditedChineseCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      maxlength: "8",
                                      "show-word-limit": "",
                                      disabled: _vm.readonly,
                                    },
                                    model: {
                                      value: _vm.dataForm.regeditedChineseCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "regeditedChineseCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.regeditedChineseCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.regeditedChineseCntS == null
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.regeditedChineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "分包商（含劳务）人数数",
                                    prop: "subcontractorChineseCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      maxlength: "8",
                                      "show-word-limit": "",
                                      disabled: _vm.readonly,
                                    },
                                    model: {
                                      value:
                                        _vm.dataForm.subcontractorChineseCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "subcontractorChineseCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.subcontractorChineseCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.regeditedChineseCntS == null
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.subcontractorChineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "align-items": "center" },
                                  attrs: { label: "项目总人数" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "0 !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.totalChineseCnt))]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.regeditedChineseCntS == null
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.totalCountS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("head-layout", {
                attrs: {
                  "head-title": "下级组织项目人员信息",
                  "head-btn-options": _vm.subordinateBtn,
                },
                on: {
                  "head-reject": _vm.reject,
                  "head-urge-processing": _vm.headUrging,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                staticClass: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayTableTopic",
                staticClass: "domesticProjectSummary",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "cell-style": _vm.cellStyle,
                },
                on: {
                  "page-current-change": _vm.tableOnLoad,
                  "page-size-change": _vm.tableOnLoad,
                  "page-refresh-change": _vm.tableOnLoad,
                  "grid-row-detail-click": _vm.rowView,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return undefined
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              this.type == "edit"
                ? "境内项目人员信息编辑"
                : this.type == "view"
                ? "境内项目人员信息查看"
                : "境内项目人员信息新增",
            "append-to-body": true,
            "close-on-click-modal": false,
            visible: _vm.isFormDialog,
            width: "70%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isFormDialog = $event
            },
            close: _vm.domesticDialog,
          },
        },
        [
          _vm.isFormDialog
            ? _c("editDialog", {
                ref: "overseasProjectFillingEdit",
                attrs: { formData: _vm.formData },
                on: { domesticDialog: _vm.domesticDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }